import _define_property from "@swc/helpers/src/_define_property.mjs";
import JSBI from "jsbi";
import { Token } from "./entities/token";
export var ChainId;
(function(ChainId) {
    ChainId[ChainId["ETHEREUM"] = 1] = "ETHEREUM";
    ChainId[ChainId["RINKEBY"] = 4] = "RINKEBY";
    ChainId[ChainId["GOERLI"] = 5] = "GOERLI";
    ChainId[ChainId["EVM"] = 56] = "EVM";
    ChainId[ChainId["EVM_TESTNET"] = 15557] = "EVM_TESTNET";
    ChainId[ChainId["EVM_TESTNET1"] = 15556] = "EVM_TESTNET1";
    ChainId[ChainId["EXSAT_TESTNET"] = 15555] = "EXSAT_TESTNET";
    ChainId[ChainId["EVM_NET"] = 17777] = "EVM_NET";
})(ChainId || (ChainId = {}));
export var TradeType;
(function(TradeType) {
    TradeType[TradeType["EXACT_INPUT"] = 0] = "EXACT_INPUT";
    TradeType[TradeType["EXACT_OUTPUT"] = 1] = "EXACT_OUTPUT";
})(TradeType || (TradeType = {}));
export var Rounding;
(function(Rounding) {
    Rounding[Rounding["ROUND_DOWN"] = 0] = "ROUND_DOWN";
    Rounding[Rounding["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
    Rounding[Rounding["ROUND_UP"] = 2] = "ROUND_UP";
})(Rounding || (Rounding = {}));
export var FACTORY_ADDRESS = "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73";
// // TODO: ETH This is test version, do not depends on it
var FACTORY_ADDRESS_ETH = "0xD93801d7D3a368D94A3A32E97A20f7aC1948a5dB";
var _obj;
export var FACTORY_ADDRESS_MAP = (_obj = {}, _define_property(_obj, ChainId.ETHEREUM, FACTORY_ADDRESS_ETH), _define_property(_obj, ChainId.RINKEBY, FACTORY_ADDRESS_ETH), _define_property(_obj, ChainId.GOERLI, FACTORY_ADDRESS_ETH), _define_property(_obj, ChainId.EVM, FACTORY_ADDRESS), _define_property(_obj, ChainId.EVM_TESTNET, "0x7A453D7174799db84AE4EAdd0dDA0662bE954B56"), _define_property(_obj, ChainId.EVM_NET, "0x75782A57c6522B8B17FCc01Ff11759f4535b2752"), _define_property(_obj, ChainId.EVM_TESTNET1, "0x7A453D7174799db84AE4EAdd0dDA0662bE954B56"), _define_property(_obj, ChainId.EXSAT_TESTNET, "0xE25308B52d349A2dC8f0F5C2cDdA8Cb4853beCfD"), _obj);
export var INIT_CODE_HASH = "0x00fb7f630766e6a796048ea87d01acd3068e8ff67d078148a3fa3f4a84f69bd5";
var INIT_CODE_HASH_ETH = "0x57224589c67f3f30a6b0d7a1b54cf3153ab84563bc609ef41dfb34f8b2974d2d";
var _obj1;
export var INIT_CODE_HASH_MAP = (_obj1 = {}, _define_property(_obj1, ChainId.ETHEREUM, INIT_CODE_HASH_ETH), _define_property(_obj1, ChainId.RINKEBY, INIT_CODE_HASH_ETH), _define_property(_obj1, ChainId.GOERLI, INIT_CODE_HASH_ETH), _define_property(_obj1, ChainId.EVM, INIT_CODE_HASH), _define_property(_obj1, ChainId.EVM_TESTNET, "0xa6b26402ddb47609a12397932ea9563f6d23b8a0e91815a74e73c4358e3a2338"), _define_property(_obj1, ChainId.EVM_NET, "0xa6b26402ddb47609a12397932ea9563f6d23b8a0e91815a74e73c4358e3a2338"), _define_property(_obj1, ChainId.EVM_TESTNET1, "0xa6b26402ddb47609a12397932ea9563f6d23b8a0e91815a74e73c4358e3a2338"), _define_property(_obj1, ChainId.EXSAT_TESTNET, "0xa6b26402ddb47609a12397932ea9563f6d23b8a0e91815a74e73c4358e3a2338"), _obj1);
export var MINIMUM_LIQUIDITY = JSBI.BigInt(1000);
// exports for internal consumption
export var ZERO = JSBI.BigInt(0);
export var ONE = JSBI.BigInt(1);
export var TWO = JSBI.BigInt(2);
export var THREE = JSBI.BigInt(3);
export var FIVE = JSBI.BigInt(5);
export var TEN = JSBI.BigInt(10);
export var _100 = JSBI.BigInt(100);
export var _9975 = JSBI.BigInt(9975);
export var _10000 = JSBI.BigInt(10000);
export var MaxUint256 = JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
export var SolidityType;
(function(SolidityType) {
    SolidityType["uint8"] = "uint8";
    SolidityType["uint256"] = "uint256";
})(SolidityType || (SolidityType = {}));
var _obj2;
export var SOLIDITY_TYPE_MAXIMA = (_obj2 = {}, _define_property(_obj2, SolidityType.uint8, JSBI.BigInt("0xff")), _define_property(_obj2, SolidityType.uint256, JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")), _obj2);
var _obj3;
export var WETH9 = (_obj3 = {}, _define_property(_obj3, ChainId.ETHEREUM, new Token(ChainId.ETHEREUM, "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.RINKEBY, new Token(ChainId.RINKEBY, "0xc778417E063141139Fce010982780140Aa0cD5Ab", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.GOERLI, new Token(ChainId.GOERLI, "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6", 18, "WETH", "Wrapped Ether", "https://weth.io")), _obj3);
// WEOS
export var WEOS = _define_property({}, ChainId.EVM_NET, new Token(ChainId.EVM_NET, "0xc00592aa41d32d137dc480d9f6d0df19b860104f", 18, "WEOS", "Wrapped EOS", "https://explorer.evm.eosnetwork.com"));
// WEOS
export var WEVM = _define_property({}, ChainId.EVM_TESTNET, new Token(ChainId.EVM_TESTNET, "0x6cCC5AD199bF1C64b50f6E7DD530d71402402EB6", 18, "WEOS", "Wrapped EOS", "https://explorer.testnet.evm.eosnetwork.com"));
// XBTC
export var XBTC = _define_property({}, ChainId.EXSAT_TESTNET, new Token(ChainId.EXSAT_TESTNET, "0xbeFB6001dE0783C6BE9320aAd71FE89728c5DD75", 18, "XBTC", "Wrapped BTC", "https://scan-testnet.exsat.network"));
export var WEVM1 = _define_property({}, ChainId.EVM_TESTNET, new Token(ChainId.EVM_TESTNET, "0x6cCC5AD199bF1C64b50f6E7DD530d71402402EB6", 18, "WEOS", "Wrapped EOS", "https://explorer.testnet.evm.eosnetwork.com"));
var _obj4;
export var WNATIVE = (_obj4 = {}, _define_property(_obj4, ChainId.ETHEREUM, WETH9[ChainId.ETHEREUM]), _define_property(_obj4, ChainId.RINKEBY, WETH9[ChainId.RINKEBY]), _define_property(_obj4, ChainId.GOERLI, WETH9[ChainId.GOERLI]), _define_property(_obj4, ChainId.EVM_NET, WEOS[ChainId.EVM_NET]), _define_property(_obj4, ChainId.EVM_TESTNET, WEVM[ChainId.EVM_TESTNET]), _define_property(_obj4, ChainId.EXSAT_TESTNET, XBTC[ChainId.EXSAT_TESTNET]), _obj4);
var _obj5;
export var NATIVE = (_obj5 = {}, _define_property(_obj5, ChainId.ETHEREUM, {
    name: "Ether",
    symbol: "ETH",
    decimals: 18
}), _define_property(_obj5, ChainId.RINKEBY, {
    name: "Rinkeby Ether",
    symbol: "RIN",
    decimals: 18
}), _define_property(_obj5, ChainId.GOERLI, {
    name: "Goerli Ether",
    symbol: "GOR",
    decimals: 18
}), _define_property(_obj5, ChainId.EVM, {
    name: "",
    symbol: "EOS",
    decimals: 18
}), _define_property(_obj5, ChainId.EVM_NET, {
    name: "EVM Native Token",
    symbol: "EOS",
    decimals: 18
}), _define_property(_obj5, ChainId.EVM_TESTNET, {
    name: "EVM Native Token",
    symbol: "EOS",
    decimals: 18
}), _define_property(_obj5, ChainId.EXSAT_TESTNET, {
    // name: 'EVM Native Token',
    name: "Exsat Native Token",
    symbol: "BTC",
    decimals: 18
}), _obj5);
