/* eslint-disable */
import { ResetCSS } from '@noahswap/uikit'
import Script from 'next/script'
import dynamic from 'next/dynamic'
import BigNumber from 'bignumber.js'
import GlobalCheckClaimStatus from 'components/GlobalCheckClaimStatus'
import { FixedSubgraphHealthIndicator } from 'components/SubgraphHealthIndicator/FixedSubgraphHealthIndicator'
import { ToastListener } from 'contexts/ToastsContext'
import useEagerConnect from 'hooks/useEagerConnect'
import useEagerConnectMP from 'hooks/useEagerConnect.bmp'
import { useAccountEventListener } from 'hooks/useAccountEventListener'
import useSentryUser from 'hooks/useSentryUser'
import useUserAgent from 'hooks/useUserAgent'
import useThemeCookie from 'hooks/useThemeCookie'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { Fragment } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
import { useStore, persistor } from 'state'
import { NetworkModal } from 'components/NetworkModal'
import { usePollBlockNumber } from 'state/block/hooks'
// import { usePollCoreFarmData } from 'state/farms/hooks'
import { NextPage } from 'next'
import { TipSwapModal } from 'components/TipSwapModal'
import { Blocklist, Updaters } from '..'
import { SentryErrorBoundary } from '../components/ErrorBoundary'
import Menu from '../components/Menu/index'
import Providers from '../Providers'
import GlobalStyle from '../style/Global'
import { useRouter } from 'next/router'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useSwitchNetwork } from 'hooks/useSwitchNetwork'
import { useEffect, useRef } from 'react'
import { DEFAULT_CHAINID } from '../config'

const EasterEgg = dynamic(() => import('components/EasterEgg'), { ssr: false })

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function GlobalHooks() {
  usePollBlockNumber()
  useEagerConnect()
  // usePollCoreFarmData()
  useUserAgent()
  useAccountEventListener()
  useSentryUser()
  useThemeCookie()
  return null
}

function MPGlobalHooks() {
  usePollBlockNumber()
  useEagerConnectMP()
  // usePollCoreFarmData()
  useUserAgent()
  useAccountEventListener()
  useSentryUser()
  return null
}

const ALLOWED_PATHS = ['/', '/swap/', '/liquidity/', '/add/', '/find/', '/404/', '/451/']

function MyApp(props: AppProps) {
  const { pageProps, Component } = props
  // @ts-ignore
  const store = useStore(pageProps.initialReduxState)
  // 初始化，若不存在chainId则添加默认chainId
  if (typeof window !== 'undefined' && window.localStorage) {
    if (!localStorage.getItem('chainId')) {
      // localStorage.setItem('chainId', process.env.NODE_ENV === "development" ? '15557' : '17777')
      localStorage.setItem('chainId', DEFAULT_CHAINID.toString())
    }
  }

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <title>Noah</title>
        {(Component as NextPageWithLayout).mp && (
          // eslint-disable-next-line @next/next/no-sync-scripts
          <script src="https://public.eosstatic.com/static/js/mp-webview-sdk/webview-v1.0.0.min.js" id="mp-webview" />
        )}
      </Head>
      <Providers store={store}>
        <Blocklist>
          {(Component as NextPageWithLayout).mp ? <MPGlobalHooks /> : <GlobalHooks />}
          <ResetCSS />
          <GlobalStyle />
          <GlobalCheckClaimStatus excludeLocations={[]} />
          <PersistGate loading={null} persistor={persistor}>
            <Updaters />
            <App {...props} />
          </PersistGate>
        </Blocklist>
      </Providers>
      <Script
        strategy="afterInteractive"
        id="google-tag"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtag/js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTAG}');
          `,
        }}
      />
    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC<React.PropsWithChildren<unknown>>
  /** render component without all layouts */
  pure?: true
  /** is mini program */
  mp?: boolean
  /**
   * allow chain per page, empty array bypass chain block modal // EVM
   * @default [ChainId.EVM_NET]
   * */
  chains?: number[]
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? SentryErrorBoundary : Fragment
// const ProductionErrorBoundary = Fragment

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const { switchNetworkAsync, isLoading, canSwitch } = useSwitchNetwork()
  const { chainId } = useActiveWeb3React()
  const router = useRouter()
  const pathname = router.pathname
  const chain = router.query.chain

  // useEffect(() => {
  //   if(chainId == 15555 && !ALLOWED_PATHS.includes(pathname)){
  //     console.log('开始重定向')
  //     router.replace('/404')
  //   }
  // }, [chainId])

  useEffect(() => {
    if (chain) {
      // if (typeof window !== 'undefined' && window.localStorage) {
      if (chain === 'exSat') {
        if (canSwitch && chainId != 15555) {
          console.log('切换网络')
          switchNetworkAsync(15555)
        }
      } else if (chain === 'evm') {
        if (canSwitch && chainId != 17777) {
          console.log('切换网络')
          switchNetworkAsync(17777)
        }
      } else if (chain === 'evm_TESTNET') {
        if (canSwitch && chainId != 15557) {
          console.log('切换网络')
          switchNetworkAsync(15557)
        }
      }
      // localStorage.setItem('chainId', '15555')
      // }
    }
    // if(chain && chain !== 'exSat'){
    //   if (typeof window !== 'undefined' && window.localStorage) {
    //     console.log('开始存储')
    //     const currenChain = localStorage.getItem('chainId')
    //     if(canSwitch && currenChain == '15555'){
    //       switchNetworkAsync(Number(currenChain))
    //     }
    //     // localStorage.setItem('chainId', '15555')
    //   }
    // }

    // console.log('+++++++++++++++++++++++++++++++++')
    // console.log('pathname', pathname, chain)
    // console.log('+++++++++++++++++++++++++++++++++')
  }, [router.pathname, router.query])

  if (Component.pure) {
    return <Component {...pageProps} />
  }

  // Use the layout defined at the page level, if available
  const Layout = Component.Layout || Fragment
  const ShowMenu = Component.mp ? Fragment : Menu

  return (
    <ProductionErrorBoundary>
      <ShowMenu>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </ShowMenu>
      <EasterEgg iterations={2} />
      <ToastListener />
      <FixedSubgraphHealthIndicator />
      <NetworkModal pageSupportedChains={Component.chains} />
      {/* <TipSwapModal /> */}
    </ProductionErrorBoundary>
  )
}

export default MyApp
